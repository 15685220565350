export const url = process.env.REACT_APP_API_URL;

export const api = {
    assignTrial,
    selectSubscription,
    startVmForSubscription,
    stopVmForSubscription,
    resetVmPasswordForSubscription,
    checkVmStatusForSubscription,
    getCustomerPortalUrl,
    getSubscriptionPaymentLink,
};

export function assignTrial(
    bufferKey: string,
    success: ({ id, expiration }: { id: string; expiration: Date }) => void,
    error?: (status: number) => void,
) {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            trialBufferKey: bufferKey,
        }),
    };

    fetch(url + "/trial-assign", options).then((response) => {
        if (response.status !== 200) {
            if (error) {
                error(response.status);
            }

            return;
        }

        response.json().then((json) => {
            success({
                id: json.id,
                expiration: new Date(json.expiration),
            });
        });
    });
}

export async function selectSubscription(
    token: string,
    subscriptionId: string,
    success: ({ key, expiration }: { key: string; expiration: Date }) => void,
    error?: (status: number) => void,
) {
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        }),
    };

    const response = await fetch(url + "/subscription-select", fetchOptions);
    if (response.status !== 200) {
        if (error) {
            error(response.status);
        }

        return;
    }
    const json = await response.json();
    if (json.actionTaken === "connect") {
        success({
            key: json.key,
            expiration: new Date(json.expiration),
        });
    }
}

function subscriptonFetchOptions(token: string, subscriptionId: string) {
    return {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        }),
    };
}

export async function startVmForSubscription(
    token: string,
    subscriptionId: string,
    success?: () => void,
    error?: (status: number) => void,
) {
    await fetch(url + "/subscription-start", subscriptonFetchOptions(token, subscriptionId)).then((response) => {
        if (response.status !== 200) {
            if (error) {
                error(response.status);
            }

            return;
        }

        if (success) {
            success();
        }
    });
}

export async function stopVmForSubscription(
    token: string,
    subscriptionId: string,
    success?: () => void,
    error?: (status: number) => void,
) {
    await fetch(url + "/subscription-stop", subscriptonFetchOptions(token, subscriptionId)).then((response) => {
        if (response.status !== 200) {
            if (error) {
                error(response.status);
            }

            return;
        }

        if (success) {
            success();
        }
    });
}

export async function checkVmStatusForSubscription(token: string, subscriptionId: string) {
    await fetch(url + "/subscription-checkstatus", subscriptonFetchOptions(token, subscriptionId));
}

export async function getCustomerPortalUrl(token: string) {
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            data: {
                returnUrl: window.location.origin,
            },
        }),
    };

    const response = await fetch(url + "/ext-firestore-stripe-subscriptions-createPortalLink", fetchOptions);
    const json = await response.json();
    return json.result.url;
}

export async function getSubscriptionPaymentLink(token: string, subscriptionId: string) {
    const response = await fetch(
        url + "/subscription-getLatestInvoicePaymentLink",
        subscriptonFetchOptions(token, subscriptionId),
    );
    const json = await response.json();
    return json.hostedPaymentLink;
}

export async function resetVmPasswordForSubscription(
    token: string,
    subscriptionId: string,
    onVmStart?: () => void,
    onResetSuccess?: () => void,
    error?: () => void,
) {
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        }),
    };

    const response = await fetch(url + "/subscription-resetpassword", fetchOptions);
    if (response.status < 200 || response.status > 299) {
        if (error) {
            error();
        }
        return;
    }
    const json = await response.json();
    if (json.actionTaken === "start" && onVmStart) onVmStart();
    if (json.actionTaken === "reset" && onResetSuccess) onResetSuccess();
}

export async function resetVmToFactoryForSubscription(
    token: string,
    subscriptionId: string,
    onResetSuccess?: () => void,
    error?: () => void,
) {
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        }),
    };

    const response = await fetch(url + "/subscription-vmfactoryreset", fetchOptions);
    if (response.status < 200 || response.status > 299) {
        if (error) {
            error();
        }
        return;
    }
    const json = await response.json();
    console.log('reset to factory resp', json)

    if (onResetSuccess) {
        onResetSuccess();
    }
}

export async function getConsumedHours(token: string, subscriptionId: string) {
    const response = await fetch(url + "/subscription-getconsumedhours", subscriptonFetchOptions(token, subscriptionId));
    const json = await response.json();
    return json.hours;
}
