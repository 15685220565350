import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSubscriptionVm from "./useSubscriptionVm";
import VmSettingsPanel from "./VmSettingsPanel";
import VmControlPanel from "./VmControlPanel";
import FactoryResetPanel from "./FactoryResetPanel";
import SubscriptionStatusPanel from "./SubscriptionStatusPanel";
import { SUB_STATUS } from "./subStatus";
import { UserContext } from "../Authentication/UserContext";
import { getConsumedHours } from "../api";
import { SUBSCRIPTION_TYPE } from "./subscriptionType";

const Subscription = (props: SubscriptionProps) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isVmSettingsVisible, setIsVmSettingsVisible] = useState(false);
  const [isFactoryResetVisible, setIsFactoryResetVisible] = useState(false);

  const {
    connectToVm,
    isConnecting,
    resetPassword,
    isResettingPassword,
    turnSubscriptionVmOff,
    isTurningVmOff,
    turnSubscriptionVmOn,
    isTurningVmOn,
    isVmInTransition,
    resetToFactory,
    isResettingToFactory
  } = useSubscriptionVm(
    props.model.id,
    props.model.vmStatus,
    props.model.isAutoShutdownEnabled
  );

  const [consumedHours, setConsumedHours] = useState(0);

  useEffect(() => {
    async function fetchHours() {
      if (
        user &&
        props.model.status === SUB_STATUS.ACTIVE &&
        props.model.subscriptionType === SUBSCRIPTION_TYPE.PAYG
      ) {
        const usage = await getConsumedHours(user.token, props.model.id);
        if (usage) {
          setConsumedHours(usage);
        }
      }
    }
    fetchHours();
  }, [props.model.id, props.model.status, props.model.subscriptionType, user]);

  const totalHours = props.model?.totalHours;
  const isPanelsSetingsVisible = isVmSettingsVisible || isFactoryResetVisible;

  async function resetToFactoryAndGoBack() {
    setIsFactoryResetVisible(false)
    await resetToFactory(props.model.id)
  }

  return (
    <div className="container p-6">
      
      { !isVmSettingsVisible && !isFactoryResetVisible && (
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="flex-md-shrink-0 me-3 mw-100" style={{ gap: "0.5rem" }}>
          <h4 className="heading">{props.model.productName}</h4>
        </div>
        {props.model.subscriptionType === SUBSCRIPTION_TYPE.PAYG &&
          props.model.status === SUB_STATUS.ACTIVE && !isResettingToFactory && (
            <div className="d-flex flex-column align-items-center">
              <div className="mb-2 w-100">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <small className="text-start">
                    {Math.round(consumedHours)} hours used
                  </small>
                  <small className="text-end">
                    {Math.round(totalHours - consumedHours)} hours left
                  </small>
                </div>
                <div
                  className="progress"
                  style={{ height: "20px", width: "250px" }}
                >
                  <div
                    className={`progress-bar bg-${"primary"}`}
                    role="progressbar"
                    style={{
                      width: `${(consumedHours / totalHours) * 100}%`,
                      height: "20px",
                    }}
                    aria-valuenow={(consumedHours / totalHours) * 100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>
          )}
      </div>
    )}
      

      {props.model.status !== SUB_STATUS.CANCELED ? (
        <>
          <SubscriptionStatusPanel
            subscriptionId={props.model.id}
            status={props.model.status}
            vmStatus={props.model.vmStatus}
            cancelAt={props.model.cancelAt}
            start={props.model.start}
            passwordResetResult={props.model.passwordResetResult}
            subscriptionType={props.model.subscriptionType}
            isPanelsSetingsVisible={isPanelsSetingsVisible}
            performingFactoryReset={isResettingToFactory}
          />

          {props.model.status === SUB_STATUS.ACTIVE && props.model.vmStatus && (
              <>
              {(isVmSettingsVisible || isFactoryResetVisible) ? (
                <>
                  {isVmSettingsVisible && (
                     <VmSettingsPanel
                        subscriptionId={props.model.id}
                        isVmInTransition={isVmInTransition}
                        resetPassword={resetPassword}
                        isResettingPassword={isResettingPassword}
                        setIsVmSettingsVisible={setIsVmSettingsVisible}
                        setIsFactoryResetVisible={setIsFactoryResetVisible}
                      />   
                    )}

                    {isFactoryResetVisible && (
                      <FactoryResetPanel
                          subscriptionId={props.model.id}
                          setIsFactoryResetVisible={setIsFactoryResetVisible}
                          resetToFactory={() => resetToFactoryAndGoBack()}
                        />
                    )}
                </>
              ) : (
                <>
                {!isResettingToFactory && (
                  <>
                  <VmControlPanel
                    subscriptionId={props.model.id}
                    vmStatus={props.model.vmStatus}
                    isConnecting={isConnecting}
                    isVmInTransition={isVmInTransition}
                    isTurningVmOff={isTurningVmOff}
                    isTurningVmOn={isTurningVmOn}
                    connectToVm={connectToVm}
                    turnSubscriptionVmOff={turnSubscriptionVmOff}
                    turnSubscriptionVmOn={turnSubscriptionVmOn}
                    setIsVmSettingsVisible={setIsVmSettingsVisible}
                  />
                  </>
                )}
                </>
              )}
              </>
          )}
        </>
      ) : (
        <>
          <p className="mt-4 fs-5">Expired & deleted</p>
          <div className="mt-4">
            <div className="row gx-1">
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-primary btn-fix-size btn-rounded"
                  onClick={() =>
                    navigate(`/subscribe?app=${props.model.urlKey}`)
                  }
                >
                  Create new subscription
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export type PasswordResetResultModel = {
  isSuccess: Boolean;
  timestamp: Date;
};

export type SubscriptionModel = {
  id: string;
  priceId: string;
  totalHours: number;
  productName: string;
  start: Date;
  end: Date;
  cancelAt?: Date;
  status: string;
  vmStatus: string;
  isAutoShutdownEnabled: boolean;
  passwordResetResult?: PasswordResetResultModel;
  urlKey: string;
  subscriptionType: string;
  virtualMachineId: string;
};

export type ShutdownModel = {
  id: string;
  autoShutdownTime: Date;
};

export type SubscriptionProps = {
  model: SubscriptionModel;
};

export type UsageSubscriptionModel = {
  hours: number;
};

export default Subscription;
