import { useState } from "react";
import LoadingButton from "../Components/LoadingButton";
import SettingsShutdownTimer from "../Components/SettingsShutdownTimer";
import ShutdownTimer from "../Components/ShutdownTimer";

const VmSettingsPanel = (props: VmSettingsProps) => {
    function showFactoryReset() {
        props.setIsFactoryResetVisible(true);
        props.setIsVmSettingsVisible(false);
    }

    return (
        <>
            <div className="row-settings">
                <h5 className="fw-semibold">Settings</h5>
            </div>

            <div className="row-settings border-top">
                <div className="row align-items-center">
                    <label className="col-md-6 fs-5" htmlFor="autoShutdownCheck">
                        Auto shutdown after
                    </label>
                    <div className="col-md-4">
                        <SettingsShutdownTimer
                            subscriptionId={props.subscriptionId}
                        />
                    </div>
                </div>

            </div>
            <div className="row-settings border-top">
                <div className="row">
                    <div className="col-md-6 fs-5">Reset password</div>
                    <div className="col-md-4">
                        <div className="text-end">
                            <LoadingButton
                                title="Reset VPS password"
                                loadingTitle="Resetting..."
                                isLoading={props.isResettingPassword}
                                isDisabled={props.isVmInTransition}
                                className="mx-0"
                                onClick={() => props.resetPassword(props.subscriptionId)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-settings border-top">
                <div className="row">
                    <div className="col-md-6 fs-5">Reset VPS to factory settings</div>
                    <div className="col-md-4">
                        <div className="text-end">
                            <button
                                type="button"
                                className="btn btn-primary btn-fix-size btn-rounded"
                                onClick={() => showFactoryReset()}
                                aria-label="Settings"
                            >
                                Factory reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 offset-md-4">
                    <button
                        type="button"
                        className="btn btn-outline-primary btn-fix-size btn-rounded"
                        onClick={() => props.setIsVmSettingsVisible(false)}
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    );
};

export type VmSettingsProps = {
    subscriptionId: string;
    isVmInTransition: boolean;
    isResettingPassword: boolean;
    setIsVmSettingsVisible: Function;
    resetPassword: Function;
    setIsFactoryResetVisible: Function;
};

export default VmSettingsPanel;
